// Import dependencies
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import { TitleText } from "../styled-components/UILibrary";
import GeneralButton from "../components/form/buttons/GeneralButton";
import InputField from "../components/form/input/inputField";

// Import styles
import "./account.css";
import "./forgot-password.css";

/*
    Reset Password page
*/
const ResetPassword = () => {
  // GraphQL image query
  const images = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "bg-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <Layout noFooter>
      <SEO title="Reset Password" />

      <BackgroundImage
        Tag="div"
        className="account_container"
        fluid={images.bg.childImageSharp.fluid}
        backgroundColor={`#ffffff`}
      >
          <div className="forgot-password__content">
            <TitleText subTitle center margin="margin-bottom: 25px">
              Enter new password
            </TitleText>

            <InputField placeholder="Password" password noForm />
            <InputField placeholder="Confirm password" password noForm />

            <div className="forgot-password__button">
              <GeneralButton
                title="RESET"
                type="primary"
                fit
                link="/forgot-password-confirmed"
                margin={{ marginTop: 15 }}
              />
            </div>
          </div>
      </BackgroundImage>
    </Layout>
  );
};

export default ResetPassword;
